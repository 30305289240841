.home-hero {
  background-color: #191a25;
  padding: 209px 30px 108px;
  margin-top: -110px;

  @media (max-width: 992px) {
    padding: 100px 30px 90px;
  }

  > .container-general {
    display: flex;

    @media (max-width: 992px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &-content {
    @media (max-width: 992px) {
      max-width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;

      .btn-blue {
        max-width: none;
      }
    }

    max-width: 50%;
    width: 100%;

    h1 {
      color: #ffffff;
      font-family: 'Berlin Sans FB Regular';
      font-size: 50px;
      letter-spacing: 0;
      line-height: 55px;
      max-width: 468px;
      margin-bottom: 24px;
    }

    h5 {
      color: #ffffff;
      font-family: 'Lato';
      font-size: 18px;
      letter-spacing: 0;
      line-height: 27px;
      max-width: 468px;
      margin-bottom: 39px;
    }
  }

  &-image {
    @media (max-width: 992px) {
      max-width: 100%;
      margin-bottom: -70px;
    }

    max-width: 50%;
    width: 100%;
    position: relative;

    img {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;

      @media (min-width: 1200px) {
        width: 650px;
        height: 648px;
        bottom: auto;
        top: -148px;
      }

      @media (max-width: 992px) {
        position: relative;
        display: block;
      }
    }
  }
}
